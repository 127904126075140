<template>
  <div class="container order-data">
    <div class="fx fx-center fx-a-i-start mg-1-top mb-fx-d-c fx-wrap">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        :opacity="0.97"
        :z-index="2147480000"
      ></loading>
      <div class="order-data-checkout-options fx wd-70 fx-wrap">
        <div class="wd-100" v-if="hasShipping" v-show="phase === 0">
          <div class="cart-checkout-options fx fx-direct-cont">
            <div class="txt-align-center">
              <span class="text-adress-title"
                >Escolha um Endereço de Entrega</span
              >
            </div>
          </div>
          <div
            class="radio-btn-group fx fx-direct-cont mg-1-top mg-2-bot"
            v-if="!force_other_address_mode"
          >
            <div
              class="address-confirm green-shadow pd-1-em wd-60"
              @click="nextPhase()"
            >
              <span v-if="user.name">{{ user.name }}</span>

              <div>
                <span v-if="user.business_address.address"
                  >{{ user.business_address.address }},
                  {{ user.business_address.number }}</span
                >

                <span v-if="user.business_address.complement">
                  -
                  {{ user.business_address.complement }}
                </span>

                <br />
                <span v-if="user.business_address.neighborhood">{{
                  user.business_address.neighborhood
                }}</span>
                <br />
                <span v-if="user.business_address.zipcode">{{
                  user.business_address.zipcode
                }}</span>
                <span v-if="user.business_address.city">
                  {{ user.business_address.city }},
                  {{ user.business_address.state }}</span
                >
              </div>
            </div>

            <div class="mg-top-2">
              <div class="switch">
                <input
                  type="checkbox"
                  class="switch__input"
                  id="has_observations"
                  v-model="has_observations"
                  :true-value="true"
                  :false-value="false"
                />
                <label for="has_observations" class="switch__label"></label>
              </div>
              <label class="mg-right-2-em" for="has_observations"
                >Observações sobre o Pedido
              </label>
            </div>

            <div
              class="
                company-data
                fx fx-direct-cont
                square-form
                fx-a-i-start
                green-shadow
                pd-1-em
              "
              v-if="has_observations === true"
            >
              <div class="wd-100">
                <div>
                  <div>
                    <input
                      type="text"
                      class="c-ui-input__field mg-1-bot"
                      v-model="observation_message_screen"
                      placeholder="Observação"
                    />
                  </div>
                  <!-- {{ Anaobservation }} -->
                </div>
              </div>
            </div>

            <div class="mg-top-2">
              <div class="switch">
                <input
                  type="checkbox"
                  class="switch__input"
                  id="radio-custom-address"
                  v-model="use_other_address"
                  :true-value="true"
                  :false-value="false"
                />
                <label for="radio-custom-address" class="switch__label"></label>
              </div>
              <label class="mg-right-2-em" for="radio-custom-address"
                >Entregar em Outro Endereço</label
              >
            </div>

            <div
              class="mg-1-top fx fx-direct-cont"
              v-if="
                use_other_address === 'go_fetch' &&
                ((delivery_days && isFinite(delivery_days)) ||
                  (shipping_price && isFinite(shipping_price)))
              "
            >
              <div>
                <template>Retirada em</template>
                <template>
                  <span>{{ delivery_days }}</span>
                  <template v-if="delivery_days > 1">Dias</template>
                  <template v-else>Dia</template>
                </template>
                <template>
                  <template v-if="delivery_days > 1">úteis</template>
                  <template v-else>útil</template>
                </template>
                <template
                  v-if="ephemeral_result && ephemeral_result.full_amount > 0"
                >
                  <br />
                  Você receberá um e-mail quando o pagamento for confirmado.
                  Endereço da Loja Física {{ storeAdress }}
                </template>
              </div>
            </div>
          </div>
          <div
            class="
              company-data
              fx fx-direct-cont
              square-form
              fx-a-i-start
              green-shadow
              pd-1-em
            "
            v-if="use_other_address === true"
          >
            <div class="wd-100">
              <div>
                <the-mask
                  mask="#####-###"
                  type="text"
                  :masked="true"
                  name="destination_address.zipcode"
                  id="destination_address.zipcode"
                  @change.native="cepera($event)"
                  @keyup.native="cepera($event)"
                  @keydown.native="cepera($event)"
                  class="c-ui-input__field mg-1-bot"
                  placeholder="CEP"
                ></the-mask>
              </div>
            </div>
            <div v-show="isCepValid" class="fx fx-direct-cont wd-100">
              <div>
                <div>
                  <input
                    type="text"
                    class="c-ui-input__field mg-1-bot"
                    name="destination_address.state"
                    v-model="destination_address.state"
                    placeholder="Estado"
                  />
                </div>
              </div>
              <div>
                <div>
                  <input
                    type="text"
                    class="c-ui-input__field mg-1-bot"
                    name="destination_address.city"
                    v-model="destination_address.city"
                    placeholder="Cidade"
                  />
                </div>
              </div>
              <div>
                <div>
                  <input
                    type="text"
                    class="c-ui-input__field mg-1-bot"
                    name="destination_address.neighborhood"
                    v-model="destination_address.neighborhood"
                    placeholder="Bairro"
                  />
                </div>
              </div>
              <div>
                <div>
                  <input
                    type="text"
                    class="c-ui-input__field mg-1-bot"
                    name="destination_address.address"
                    v-model="destination_address.address"
                    placeholder="Rua"
                  />
                </div>
              </div>
              <div>
                <div>
                  <input
                    type="text"
                    class="c-ui-input__field mg-1-bot"
                    name="destination_address.number"
                    v-model="destination_address.number"
                    placeholder="Número"
                  />
                </div>
              </div>
              <div>
                <div>
                  <input
                    type="text"
                    class="c-ui-input__field"
                    name="destination_address.complement"
                    v-model="destination_address.complement"
                    placeholder="Complemento"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="input-group fx fx-direct-cont wd-100 shipping-method"
          v-if="hasShipping && use_other_address !== 'go_fetch'"
          v-show="phase === 1"
        >
          <div class="cart-checkout-options wd-100 fx fx-direct-cont mg-1-bot">
            <div class="txt-align-center">
              <span class="text-shipping-choose"
                >Escolha um Método de Entrega</span
              >
            </div>
          </div>
          <div class="input-group fx fx-center fx-direct-cont fx-a-i-start">
            <label
              v-if="
                enabled_shipping_methods.includes('express_delivery') &&
                (raw_ephemeral_result || {}).shipping_amount == 0
              "
              class="
                fx fx-direct-cont fx-center
                green-shadow
                mg-1-bot
                wd-50
                pd-05-em
              "
              for="radio-free"
            >
              <div>
                <div>
                  <input
                    type="radio"
                    name="shipping_method"
                    value="express_delivery"
                    v-model="shipping_method"
                    id="radio-free"
                    class="form-radio free shadow"
                  />
                </div>

                <div class="ship-method main-product-title txt-align-center">
                  <span>Frete Grátis</span>
                </div>
              </div>

              <div
                class="fx fx-center fx-direct-cont shipping-data"
                v-if="
                  ephemerals_by_shipping &&
                  ephemerals_by_shipping['express_delivery'] &&
                  ephemerals_by_shipping['express_delivery'].result
                "
              >
                <span>
                  <span class="mg-1-bot">
                    <template>
                      <span class="main-product-title">{{
                        ephemerals_by_shipping["express_delivery"].result
                          .shipping_days
                      }}</span>
                      <span class="mg-l-r-1">
                        <template
                          v-if="
                            ephemerals_by_shipping['express_delivery'].result
                              .shipping_days > 1
                          "
                        >
                          <span>Dias</span>
                        </template>
                        <template v-else>
                          <span>Dia</span>
                        </template>
                      </span>
                    </template>
                  </span>
                  <span>
                    <template>
                      <template
                        v-if="
                          ephemerals_by_shipping['express_delivery'].result
                            .shipping_days > 1
                        "
                      >
                        <span>úteis</span>
                      </template>
                      <template v-else>
                        <span>útil</span>
                      </template>
                    </template>
                    <template
                      v-if="
                        ephemerals_by_shipping['express_delivery'].result
                          .full_amount > 0
                      "
                    ></template>
                  </span>
                </span>
              </div>
            </label>
            <div></div>
            <label
              v-if="
                ephemerals_by_shipping['sedex'] &&
                enabled_shipping_methods.includes('sedex') &&
                !((raw_ephemeral_result || {}).shipping_amount == 0)
              "
              class="
                fx fx-direct-cont fx-center
                green-shadow
                mg-1-bot
                wd-50
                pd-05-em
              "
              for="radio-sedex"
            >
              <div>
                <div>
                  <input
                    type="radio"
                    name="shipping_method"
                    value="sedex"
                    id="radio-sedex"
                    class="form-radio sedex shadow"
                    v-model="shipping_method"
                  />
                </div>

                <div class="ship-method main-product-title txt-align-center">
                  <span>Sedex</span>
                </div>
              </div>
              <div
                class="fx fx-center fx-direct-cont shipping-data"
                v-if="
                  ephemerals_by_shipping &&
                  ephemerals_by_shipping['sedex'] &&
                  ephemerals_by_shipping['sedex'].result
                "
              >
                <span>
                  <template>
                    <span class="main-product-title">{{
                      ephemerals_by_shipping["sedex"].result.shipping_days
                    }}</span>
                    <span class="mg-l-r-1">
                      <template
                        v-if="
                          ephemerals_by_shipping['sedex'].result.shipping_days >
                          1
                        "
                      >
                        <span>Dias</span>
                      </template>
                      <template v-else>
                        <span>Dia</span>
                      </template>
                    </span>
                  </template>
                  <template>
                    <template
                      v-if="
                        ephemerals_by_shipping['sedex'].result.shipping_days > 1
                      "
                    >
                      <span>úteis</span>
                    </template>
                    <template v-else>
                      <span>útil</span>
                    </template>
                  </template>
                </span>

                <template
                  v-if="ephemerals_by_shipping['sedex'].result.full_amount > 0"
                  class="mg-1-top"
                ></template>

                <span
                  class="main-product-title"
                  v-if="
                    ephemerals_by_shipping['sedex'].result.shipping_amount &&
                    isFinite(
                      ephemerals_by_shipping['sedex'].result.shipping_amount
                    ) &&
                    ephemerals_by_shipping['sedex'].result.shipping_amount > 0
                  "
                >
                  <span class="main-product-title">{{
                    ephemerals_by_shipping["sedex"].result.shipping_amount
                      | toCurrency
                  }}</span>
                </span>
              </div>
            </label>
            <label
              v-if="
                ephemerals_by_shipping['pac'] &&
                enabled_shipping_methods.includes('pac') &&
                !((raw_ephemeral_result || {}).shipping_amount == 0)
              "
              class="
                fx fx-direct-cont fx-center
                green-shadow
                mg-1-bot
                wd-50
                pd-05-em
              "
              for="radio-pac"
            >
              <div>
                <input
                  type="radio"
                  name="shipping_method"
                  value="pac"
                  v-model="shipping_method"
                  id="radio-pac"
                  class="form-radio pac shadow"
                />
                <div class="ship-method fx-center fx-direct-cont">
                  <span class="main-product-title">PAC</span>
                </div>
              </div>

              <div
                class="fx fx-center fx-direct-cont shipping-data"
                v-if="
                  ephemerals_by_shipping &&
                  ephemerals_by_shipping['pac'] &&
                  ephemerals_by_shipping['pac'].result
                "
              >
                <span>
                  <template>
                    <span class="main-product-title">{{
                      ephemerals_by_shipping["pac"].result.shipping_days
                    }}</span>
                    <span class="mg-l-r-1">
                      <template
                        v-if="
                          ephemerals_by_shipping['pac'].result.shipping_days > 1
                        "
                      >
                        <span>Dias</span>
                      </template>
                      <template v-else>
                        <span>Dia</span>
                      </template>
                    </span>
                  </template>
                  <template>
                    <template
                      v-if="
                        ephemerals_by_shipping['pac'].result.shipping_days > 1
                      "
                    >
                      <span>úteis</span>
                    </template>
                    <template v-else>
                      <span>útil</span>
                    </template>
                  </template>
                </span>

                <template
                  v-if="ephemerals_by_shipping['pac'].result.full_amount > 0"
                ></template>
                <span
                  class="main-product-title"
                  v-if="
                    ephemerals_by_shipping['pac'].result.shipping_amount &&
                    isFinite(
                      ephemerals_by_shipping['pac'].result.shipping_amount
                    ) &&
                    ephemerals_by_shipping['pac'].result.shipping_amount > 0
                  "
                >
                  <span class="main-product-title">{{
                    ephemerals_by_shipping["pac"].result.shipping_amount
                      | toCurrency
                  }}</span>
                </span>
              </div>
            </label>
            <label
              v-if="
                ephemerals_by_shipping['express_delivery'] &&
                enabled_shipping_methods.includes('express_delivery') &&
                !((raw_ephemeral_result || {}).shipping_amount == 0) &&
                (!ephemerals_by_shipping['express_delivery'] ||
                  !ephemerals_by_shipping['express_delivery'].result ||
                  ephemerals_by_shipping['express_delivery'].result
                    .shipping_amount < 91239124)
              "
              class="
                fx fx-direct-cont fx-center
                green-shadow
                mg-1-bot
                wd-50
                pd-05-em
              "
              for="radio-express_delivery"
            >
              <div>
                <input
                  type="radio"
                  name="shipping_method"
                  value="express_delivery"
                  v-model="shipping_method"
                  id="radio-express_delivery"
                  class="form-radio courier shadow"
                />
                <div class="ship-method fx-center fx-direct-cont">
                  <span class="main-product-title">Courier</span>
                </div>
              </div>
              <div
                class="fx fx-center fx-direct-cont shipping-data"
                v-if="
                  ephemerals_by_shipping &&
                  ephemerals_by_shipping['express_delivery'] &&
                  ephemerals_by_shipping['express_delivery'].result
                "
              >
                <span>
                  <template>
                    <span class="main-product-title">{{
                      ephemerals_by_shipping["express_delivery"].result
                        .shipping_days
                    }}</span>
                    <span class="mg-l-r-1">
                      <template
                        v-if="
                          ephemerals_by_shipping['express_delivery'].result
                            .shipping_days > 1
                        "
                      >
                        <span>Dias</span>
                      </template>
                      <template v-else>
                        <span>Dia</span>
                      </template>
                    </span>
                  </template>

                  <template>
                    <template
                      v-if="
                        ephemerals_by_shipping['express_delivery'].result
                          .shipping_days > 1
                      "
                    >
                      <span>úteis</span>
                    </template>
                    <template v-else>
                      <span>útil</span>
                    </template>
                  </template>
                </span>

                <template
                  v-if="
                    ephemerals_by_shipping['express_delivery'].result
                      .full_amount > 0
                  "
                ></template>
                <span
                  v-if="
                    ephemerals_by_shipping['express_delivery'].result
                      .shipping_amount &&
                    isFinite(
                      ephemerals_by_shipping['express_delivery'].result
                        .shipping_amount
                    ) &&
                    ephemerals_by_shipping['express_delivery'].result
                      .shipping_amount > 0
                  "
                >
                  <span class="main-product-title mg-1-top">{{
                    ephemerals_by_shipping["express_delivery"].result
                      .shipping_amount | toCurrency
                  }}</span>
                </span>
              </div>
            </label>
            <label
              
              for="radio-go_fetch"
              v-if="enabled_shipping_methods.includes('go_fetch')"
              class="go_fetch
                fx fx-direct-cont fx-center
                green-shadow
                mg-1-bot
                wd-50
                pd-05-em"
            >
              <div id='remove_store'>
                <input
                  type="radio"
                  name="shipping_method"
                  value="go_fetch"
                  v-model="shipping_method"
                  id="radio-go_fetch"
                  class="form-radio store shadow go_fetch"
                />
                <div class="ship-method fx-center fx-direct-cont">
                  <span class="main-product-title">Retirar na Loja</span>
                </div>
              </div>

              <div
                class="fx fx-center fx-direct-cont shipping-data"
                v-if="
                  ephemerals_by_shipping &&
                  ephemerals_by_shipping['go_fetch'] &&
                  ephemerals_by_shipping['go_fetch'].result
                "
              >
                <!-- Retirada em até -->
                <span>
                  <span>
                    <template>
                      <span class="main-product-title">{{
                        ephemerals_by_shipping["go_fetch"].result.shipping_days
                      }}</span>
                      <span>
                        <span class="mg-l-r-1">
                          <template
                            v-if="
                              ephemerals_by_shipping['go_fetch'].result
                                .shipping_days > 1
                            "
                          >
                            <span>Dias</span>
                          </template>
                          <template v-else>
                            <span>Dia</span>
                          </template>
                        </span>
                      </span>
                    </template>
                  </span>

                  <template>
                    <template
                      v-if="
                        ephemerals_by_shipping['go_fetch'].result
                          .shipping_days > 1
                      "
                    >
                      <span>úteis</span>
                    </template>
                    <template v-else>
                      <span>útil</span>
                    </template>
                  </template>
                  <template
                    v-if="
                      ephemerals_by_shipping['go_fetch'].result.full_amount > 0
                    "
                  ></template>
                </span>
                <span class="main-product-title">R$ 0,00</span>
                <span
                  v-if="
                    ephemerals_by_shipping['go_fetch'].result.shipping_amount &&
                    isFinite(
                      ephemerals_by_shipping['go_fetch'].result.shipping_amount
                    ) &&
                    ephemerals_by_shipping['go_fetch'].result.shipping_amount >
                      0
                  "
                >
                  Preço da Retirada
                  <span>{{
                    ephemerals_by_shipping["go_fetch"].result.shipping_amount
                      | toCurrency
                  }}</span>
                </span>
              </div>
            </label>
          </div>
        </div>
        <!-- <div
          class="input-group fx fx-direct-cont fx-wrap wd-50"
          v-if="coupons_enabled && ephemeral_result && ephemeral_result.full_amount > 0"
          v-show="phase === 2"
        >
          <div class="cart-checkout-options wd-100 fx fx-direct-cont">
            <div class="txt-align-center">
              <span>Cupons de Desconto</span>
            </div>
          </div>
          <div class="pd-1-em order-form">
            <div v-for="coupon in coupons" :key="coupon.id">
              <span>{{ coupon.code }}</span>:
              <span>
                Garante
                <template v-if="coupon.modifier_type === 'PERCENTAGE'">{{ coupon.amount }}%</template>
                <template v-if="coupon.modifier_type === 'PRICE'">{{ coupon.amount | toCurrency }}</template>
                de desconto
              </span>
            </div>
            <input
              class="default-border wd-100 shadow"
              type="text"
              v-model="couponInput"
              placeholder="Código do Cupom"
            />
            <div class="input-group fx-main">
              <button class="btn clickable" v-on:click="addCoupon()">Adicionar Cupom</button>
            </div>
          </div>
        </div>-->

        <div
          class="input-group fx fx-direct-cont wd-100"
          v-if="ephemeral_result && ephemeral_result.full_amount > 0"
          v-show="phase === 2"
        >
          <div class="cart-checkout-options wd-100 fx fx-direct-cont mg-2-bot">
            <div class="txt-align-center">
              <span class="text-payment">Escolha uma Forma de Pagamento</span>
            </div>
          </div>
          <!-- <pre> {{ enabled_payment_methods }} </pre>
        <pre> {{ parseInt(max_credit_card_value) }} </pre>
        <pre> {{ parseInt(ephemeral_result.full_amount)  }} </pre> -->

          <div class="input-group fx square-form mg-0-auto">
            <div
              v-if="
                (enabled_payment_methods.includes('credit_card') ||
                  this.hasPlottedPrice) &&
                parseInt(ephemeral_result.full_amount) <=
                  parseInt(max_credit_card_value)
              "
              class="
                payment-method
                wd-50
                fx fx-direct-cont
                txt-align-center
                fx-center
              "
            >
              <label for="rb_credit_card" class="p-label"
                >Cartão de Crédito
              </label>
              <input
                type="radio"
                name="payment_method"
                v-model="payment_method"
                value="credit_card"
                id="rb_credit_card"
                class="form-radio payment-card mg-1-top"
              />
            </div>
            <div
              v-if="enabled_payment_methods.includes('boleto')"
              class="
                payment-method
                wd-50
                fx fx-direct-cont
                txt-align-center
                fx-center
              "
            >
              <label for="rb_boleto" class="p-label">Boleto</label>
              <input
                type="radio"
                name="payment_method"
                v-model="payment_method"
                value="boleto"
                id="rb_boleto"
                class="form-radio payment-barcode mg-1-top"
              />  
            </div>
            
            <div
              v-if="enabled_payment_methods.includes('pix')"
              class="
                payment-method
                wd-50
                fx fx-direct-cont
                txt-align-center
                fx-center
              "
            >
              <label for="rb_pix" class="p-label">PIX</label>
              <input
                type="radio"
                name="payment_method"
                v-model="payment_method"
                value="pix"
                id="rb_pix"
                class="form-radio payment-pix mg-1-top"
              />
           
           </div>

            <div
              v-if="enabled_payment_methods.includes('no_op')"
              class="
                payment-method
                wd-50
                fx fx-direct-cont
                txt-align-center
                fx-center
              "
            >
              <label for="rb_no_op" class="p-label">Pagar ao Receber</label>
              <input
                type="radio"
                name="payment_method"
                v-model="payment_method"
                value="no_op"
                id="rb_no_op"
                class="form-radio payment-maquininha"
              />
            </div>
          </div>
        </div>
        <div
          class="order-form payment-form"
          v-if="payment_method === 'boleto' && phase === 2"
        >
          <div>
            <button class="btn clickable mg-1-top" @click="nextPhase()">
              <span class="mg-right-1-em">{{ continueText }}</span>
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>

        <div
          class="order-form payment-form"
          v-if="payment_method === 'credit_card' && phase === 2"
        >
          <div class="fx input-group fx-main fx-direct-cont">
            <input
              type="text"
              v-model="payment_data.card_number"
              name="card_number"
              id="card_number"
              class="default-border mg-1-top"
              placeholder="Número do Cartão"
            />
            <input
              type="text"
              v-model="payment_data.card_holder_name"
              name="card_holder_name"
              id="card_holder_name"
              class="default-border mg-1-top"
              placeholder="Nome do Titular"
            />

            <the-mask
              :mask="['##/##/####']"
              :masked="true"
              type="text"
              v-model="payment_data.card_holder_birthdate"
              name="card_holder_birthdate"
              id="card_holder_birthdate"
              class="default-border mg-1-top"
              placeholder="Data de Nascimento do Titular"
            />

            <the-mask
              :mask="['##/##', '##/####']"
              :masked="true"
              type="text"
              v-model="payment_data.card_expiration_date"
              name="card_expiration_date"
              id="card_expiration_date"
              class="default-border mg-1-top"
              placeholder="Data de Validade"
            />

            <the-mask
              :mask="['####']"
              :masked="true"
              type="text"
              v-model="payment_data.card_cvv"
              name="card_cvv"
              id="card_cvv"
              class="default-border mg-1-top"
              placeholder="CVV"
            />
            <!-- Without Plotted Price -->
            {{ max_installments}}
            {{ enabled_plotted }}
            <div
              v-if="max_installments != null && !enabled_plotted"
              class="mg-1-top"
            >
              <select
                v-model="payment_data.installments"
                name="card_installments"
                id="card_installments"
                class="default-border"
              >
                <option
                  v-for="i in Array.from(
                    Array(max_installments + 1).keys()
                  ).slice(1)"
                  :key="i"
                  :value="i"
                >
                  <template
                    v-if="ephemeral_result && ephemeral_result.full_amount > 0"
                    >{{ i }}x de
                    {{
                      ((ephemeral_result.full_amount - rawDiscount) / i)
                        | toCurrency
                    }}
                    sem Juros</template
                  >
                  <template v-else>{{ i }}x</template>
                </option>
              </select>
            </div>
            <!-- Plotted Price -->
            <div
              v-if="max_installments_plotted != null && enabled_plotted"
              class="mg-1-top"
            >
              <select
                v-model="payment_data.installments"
                name="card_installments"
                id="card_installments"
                class="default-border"
              >
                <option
                  v-for="i in Array.from(
                    Array(max_installments_plotted + 1).keys()
                  ).slice(1)"
                  :key="i"
                  :value="i"
                >
                  <template
                    v-if="ephemeral_result && ephemeral_result.full_amount > 0"
                    >{{ i }}x de {{ (fullAmountPlotted / i) | toCurrency }} sem
                    Juros</template
                  >
                  <template v-else>{{ i }}x</template>
                </option>
              </select>
            </div>
            <!-- Plotted Price -->
            <div>
              <button class="btn clickable mg-1-top" @click="nextPhase()">
                <span class="mg-right-1-em">{{ continueText }}</span>
                <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- <button class="btn clickable" v-show="phase === 3" v-on:click="generateOrder()">
          <span class="mg-right-1-em">Concluir Pedido</span>
          <i class="fas fa-chevron-right"></i>
        </button>-->
      </div>
      <div class="wd-30 order-data-details-cart" v-if="cartItems.length > 0">
        <div class="cart-checkout-options fx fx-direct-cont">
          <div class="txt-align-center">
            <span class="text-order-detail-cart">Detalhes do Pedido</span>
          </div>
        </div>
        <cart-item
          class="order-data-cart"
          v-for="cartItem in cartItems"
          :key="
            cartItem.product.id +
            String(cartItem.variation ? cartItem.variation.id : '')
          "
          :cartItem="cartItem"
          :remover="() => removeItemFromCart(cartItem)"
        />
        <!--------  Without Plotted Price -------------------------->
        <div
          class="fx mg-top-1 fx-direct-cont green-shadow pd-1-em"
          v-if="ephemeral_result && !this.enabled_plotted"
        >
          <div class="dsp-grid gd-tp-c-2 pd-05-em">
            <span v-if="!this.hasPlottedPrice">Subtotal</span>
            <span v-if="this.hasPlottedPrice">Subtotal (à vista) </span>
            <span class="main-product-title txt-a-r">{{
              rawAmount | toCurrency
            }}</span>
          </div>
          <div
            class="dsp-grid gd-tp-c-2 pd-05-em"
            v-if="ephemeral_result.shipping_amount < 91239124"
          >
            <span>Entrega</span>
            <span class="main-product-title txt-a-r">{{
              ephemeral_result.shipping_amount | toCurrency
            }}</span>
          </div>
          <div class="dsp-grid gd-tp-c-2 pd-05-em">
            <span>Descontos</span>
            <span class="main-product-title txt-a-r">{{
              discountedAmount | toCurrency
            }}</span>
          </div>
          <div
            class="dsp-grid gd-tp-c-2 pd-05-em"
            v-if="
              this.enabled_purchase_discount &&
              this.rawAmount >= this.purchase_amount
            "
          >
            <span>Total</span>
            <span class="main-product-title txt-a-r">{{
              (ephemeral_result.full_amount -
                (ephemeral_result.shipping_amount < 91239124
                  ? 0
                  : ephemeral_result.shipping_amount) -
                rawAmount * discount_percentage)
                | toCurrency
            }}</span>
          </div>
          <div class="dsp-grid gd-tp-c-2 pd-05-em" v-else>
            <span>Total</span>
            <span class="main-product-title txt-a-r">{{
              (ephemeral_result.full_amount -
                (ephemeral_result.shipping_amount < 91239124
                  ? 0
                  : ephemeral_result.shipping_amount))
                | toCurrency
            }}</span>
          </div>
        </div>
        <!-- Plotted Price -->
        <div
          class="fx mg-top-1 fx-direct-cont green-shadow pd-1-em"
          v-if="ephemeral_result && this.enabled_plotted"
        >
          <div class="dsp-grid gd-tp-c-2 pd-05-em">
            <span>Subtotal até {{ max_installments_plotted }}x</span>
            <span class="main-product-title txt-a-r">{{
              rawAmountPlotted | toCurrency
            }}</span>
          </div>
          <div
            class="dsp-grid gd-tp-c-2 pd-05-em"
            v-if="ephemeral_result.shipping_amount < 91239124"
          >
            <span>Entrega</span>
            <span class="main-product-title txt-a-r">{{
              ephemeral_result.shipping_amount | toCurrency
            }}</span>
          </div>
          <div class="dsp-grid gd-tp-c-2 pd-05-em">
            <span>Descontos</span>
            <span class="main-product-title txt-a-r">{{
              discountedAmount | toCurrency
            }}</span>
          </div>
          <div
            class="dsp-grid gd-tp-c-2 pd-05-em"
            v-if="
              this.enabled_purchase_discount &&
              this.rawAmount >= this.purchase_amount
            "
          >
            <span>Total</span>
            <span class="main-product-title txt-a-r">{{
              (fullAmountPlotted -
                (ephemeral_result.shipping_amount < 91239124
                  ? 0
                  : ephemeral_result.shipping_amount) -
                rawAmount * discount_percentage)
                | toCurrency
            }}</span>
          </div>
          <div class="dsp-grid gd-tp-c-2 pd-05-em" v-else>
            <span>Total</span>
            <span class="main-product-title txt-a-r">{{
              (fullAmountPlotted -
                (ephemeral_result.shipping_amount < 91239124
                  ? 0
                  : ephemeral_result.shipping_amount))
                | toCurrency
            }}</span>
          </div>
        </div>
        <!-- Plotted Price -->
      </div>

      <div class="detail-product-bar wd-100 fx fx-j-c-s-b fx-a-i-cent mg-1-top">
        <div class="container fx fx-main">
          <button
            class="p-detail-btn clickable"
            type="button"
            @click="previousPhase()"
          >
            <i class="fas fa-chevron-left"></i>
          </button>
          <button class="p-detail-btn clickable" @click="nextPhase()">
            <span class="mg-right-1-em">{{ continueText }}</span>
            <i class="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.shipping-method label {
  display: flex;
  flex-direction: row;
}
.address-confirm {
  line-height: 1.75em;
  cursor: pointer;
  box-shadow: 0px 0px 6px -2px grey !important;
  -webkit-box-shadow: 0px 0px 6px -2px grey !important;
}

.address-confirm:hover {
  box-shadow: 0px 0px 4px 2px #b88752;
}

.order-form.payment-form {
  width: 60%;
  margin: 0 auto;
}
.form-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  width: 5em;
  height: 5em;
  background-size: 5em;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.order-data-checkout-options.fx.wd-100 {
  flex-direction: column;
}

/* .order-data .dsp-grid.gd-tp-c-3.pd-1-em.wd-100 {
  grid-template-columns: 1fr 1fr !important;
} */

.fx-center.fx-direct-cont.cart-buttons {
  display: none !important;
}

.p-label {
  font-size: 0.8em;
}

.payment-card {
  background-image: url("../../assets/img/credit-card.png");
}
.payment-barcode {
  background-image: url("../../assets/img/barcode.png");
}

.payment-pix {
  background-image: url("../../assets/img/pix.png");
}

.sedex {
  background-image: url("../../assets/img/sedex.png");
}
.pac {
  background-image: url("../../assets/img/pac.png");
}
.store {
  background-image: url("../../assets/img/remove-on-store.png");
}
.courier {
  background-image: url("../../assets/img/courier.png");
}
.free {
  background-image: url("../../assets/img/money-bag.png");
}

label.fx.fx-direct-cont.fx-center.green-shadow.mg-1-bot.wd-50
  div:nth-of-type(1) {
  width: 7em;
  text-align: center;
}
.input-group.fx.fx-center.mg-top-2 .fx-center {
  width: 10em !important;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
}

input.form-radio {
  background-size: 3.5em !important;
  border-radius: 100%;
}

.cart-buttons,
.total-itens {
  display: none;
}

.fx.fx-center.fx-direct-cont.shipping-data {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.order-data-details-cart .gd-tp-c-3 {
  grid-template-columns: 1fr 100% !important;
}

.order-data-details-cart .product-name {
  width: 12em !important;
  padding-left: 0.5em !important;
}
.wd-50 {
  width: 50%;
}

div.cart-buttons {
  display: none !important;
}

.fx.fx-start.cart-buttons.fx-a-i-c {
  display: none !important;
}
.fx.fx-center.fx-direct-cont.shipping-data {
  width: 8em;
}

@media (max-width: 500px) {
  .square-form,
  .order-form.payment-form {
    width: 100%;
  }
}

.form-radio:checked + .payment-method {
  box-shadow: 0px 0px 4px 2px #086608 !important;
}

.form-radio:hover,
.address-confirm:hover,
.shipping-method label:hover {
  box-shadow: 0px 0px 4px 2px #006d00 !important;
}

.form-radio:checked {
  box-shadow: 0px 0px 4px 2px #086608;
}

.form-radio:hover .payment-method {
  box-shadow: 0px 0px 4px 2px #086608;
}

.form-radio:checked + .payment-method {
  box-shadow: 0px 0px 4px 2px #086608;
}
</style>

<script>
import router from "@/router";
import util from "@/services/util";
import User from "@/services/user";
import Cart from "@/services/cart";
import { TheMask } from "vue-the-mask";
import Coupon from "@/services/coupon";
import Order from "@/services/order";
import { debounce } from "debounce";
import axios from "axios";
import boolean from "boolean";
import Loading from "vue-loading-overlay";
import Mobile from "@/services/mobile.js";
import Features from "@/services/features";
import { buildImgUrl } from "@/services/item";
import CartItem from "@/components/storekeeper/CartItem.vue";

export default {
  name: "OrderData",
  components: {
    "the-mask": TheMask,
    Loading,
    "cart-item": CartItem,
  },
  data: function () {
    return {
      // https://logaretm.com/blog/2019-10-11-forcing-recomputation-of-computed-properties/
      backdoor: 0,
      user: null,
      loadIndex: 0,
      phase: 0,
      cartItems: [],
      coupons: [],
      enabled_purchase_discount: boolean(
        process.env.VUE_APP_ENABLE_PURCHASE_DISCOUNT || false
      ),
      purchase_amount: 15000,
      discount_percentage: 0.2,
      couponInput: "",
      payment_method: null,
      enabled_payment_methods: [],
      storeAdress: process.env.VUE_APP_STREET_ADDRESS || "",
      payment_data: {
        card_number: "",
        card_cvv: "",
        card_expiration_date: "",
        card_holder_name: "",
        installments: 1,
        pagseguro_sender_hash: "",
      },
      ephemerals_by_shipping: {},
      delivery_days: null,
      shipping_price: null,
      totalCart: 0,
      shipping_method: null,
      enabled_shipping_methods: [],
      max_installments: null,
      max_installments_plotted: null,
      raw_ephemeral_result: null,
      coupons_enabled: false,
      isMobile: Mobile.isMobile(),
      max_credit_card_value:
        process.env.VUE_APP_MAX_CREDIT_CARD_MAX_VALUE || "999999999999",
      destination_address: {
        number: "",
        zipcode: "",
        address: "",
        neighborhood: "",
        city: "",
        state: "",
        complement: "",
        observation_message: "",
      },
      has_observations_address: false,
      use_other_address: false,
      isCepValid: false,
      has_observations: false,
      observation_message_screen: "",
      buildProductDataLayer: [],
      dataLayerItems: [],
      enabled_plotted: false,
    };
  },
  beforeDestroy() {
    this.$unloadScript(
      "https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js"
    );
  },
  mounted() {
    this.$loadScript(
      "https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js"
    ).then(() => {
      let interval = "dummy";
      const checker = () => {
        if (!window.PagSeguroDirectPayment) {
          return;
        }
        window.PagSeguroDirectPayment.onSenderHashReady((response) => {
          if (response.status == "error") {
            console.error(response.message);
            return false;
          }
          this.payment_data.pagseguro_sender_hash = response.senderHash; //Hash estará disponível nesta variável.
        });
        clearInterval(interval);
      };
      interval = setInterval(checker, 1000);
    });
    this.placeEphemeralOrder();
    this.loadIndex++;
    let pfeat = Features.get()
      .then((features) => {
        this.payment_method = Object.entries(features.payment_methods)
          .filter((e) => boolean(e[1].default))
          .map((e) => e[0])[0];
        this.enabled_payment_methods = Object.entries(features.payment_methods)
          .filter((e) => boolean(e[1].enabled))
          .map((e) => e[0]);
        this.shipping_method = Object.entries(features.shipping_methods)
          .filter((e) => boolean(e[1].default))
          .map((e) => e[0])[0];
        this.enabled_shipping_methods = Object.entries(
          features.shipping_methods
        )
          .filter((e) => boolean(e[1].enabled))
          .map((e) => e[0]);
        this.max_installments =
          features.payment_methods && features.payment_methods.credit_card
            ? features.payment_methods.credit_card.maximum_installments
            : 1;
        this.max_installments_plotted =
          features.payment_methods && features.payment_methods.credit_card
            ? features.payment_methods.credit_card.maximum_installments_plotted
            : 1;
        this.coupons_enabled =
          features.coupons && boolean(features.coupons.enabled);
      })
      .finally(() => {
        this.loadIndex--;
      });
    let pcart = this.loadCart();
    let puser = User.self().then((user) => {
      if (!user.business_address) {
        // No base-address, so auto-check the other address option
        this.use_other_address = true;
        return;
      }
      this.user = user;
    });
    Promise.all([pfeat, pcart, puser]).then(
      () => this.fixPhase(),
      this.$forceUpdate(),
      this.backdoor++
    );
  },
  computed: {
    ephemeral_result: function () {
      // nao remover
      this.backdoor;
      const shipping = this.shipping_method;
      const ephs = this.ephemerals_by_shipping;
      const rawRes = this.raw_ephemeral_result;
      if (!shipping) {
        return rawRes;
      }
      if (!ephs || !(ephs[shipping] || {}).result) {
        return rawRes;
      }
      this.raw_ephemeral_result_shipping = ephs[shipping].result;
      return ephs[shipping].result;
    },
    continueText: function () {
      if (!this.payment_method || this.phase != 2) {
        return "Próximo";
      }
      switch (this.payment_method) {
        case "boleto":
          this.enabled_plotted = false;
          return "Pagar com Boleto";
        case "pix":
          this.enabled_plotted = false;
          return "Pagar com PIX";
        case "credit_card":
          this.enabled_plotted = false;
          if (this.ephemeral_result.plotted_amount > 0) {
            this.enabled_plotted = true;
          }
          return "Pagar com Cartão";
        case "no_op":
          return "Pagar ao Receber";
        default:
          return "Próximo";
      }
    },
    final_destination_address: function () {
      return this.hasShipping
        ? this.use_other_address === true || this.force_other_address_mode
          ? this.destination_address
          : this.user.business_address
        : undefined;
    },
    force_other_address_mode: function () {
      return !this.user || !this.user.business_address;
    },
    zipcode: function () {
      const zip = (this.final_destination_address || {}).zipcode;
      return String(zip).replace(/\D/g, "");
    },
    isLoading: function () {
      return this.loadIndex > 0;
    },
    hasShipping: function () {
      return (
        this.cartItems.some((i) =>
          !i.variation || (i.variation.parent_fields || []).includes("box")
            ? i.product.box
            : i.variation.box
        ) &&
        this.enabled_shipping_methods &&
        this.enabled_shipping_methods[0] != ""
      );
    },
    cartAmount: function () {
      let amount = this.rawAmount;
      for (let coupon of this.coupons) {
        if (!coupon.applies_for.includes("ITEMS")) {
          continue;
        }
        if (coupon.modifier_type === "PERCENTAGE") {
          amount = Math.ceil(amount * ((100 - coupon.amount) / 100));
        }
        if (coupon.modifier_type === "PRICE") {
          amount = amount - coupon.amount;
        }
      }
      if (
        this.enabled_purchase_discount &&
        this.rawAmount >= this.purchase_amount
      ) {
        amount = amount - this.rawAmount * this.discount_percentage;
      }
      return amount;
    },
    fullAmountPlotted: function () {

      
      
  
      return (
        parseInt(this.ephemeral_result.full_amount) +
        parseInt(this.ephemeral_result.plotted_amount)
      );
    },
    hasPlottedPrice: function () {
      let has_plotted = 0;
      //this.totalCartWithPlotted = 0;
      this.cartItems.forEach((cart) => {
        if (cart.product.plotted_price) {
          has_plotted = 1;
          //      this.totalCartWithPlotted += Number(cart.product.plotted_price * cart.quantity);
          //  } else {
          //      this.totalCartWithPlotted += Number(cart.full_price);
        }
      });
      return has_plotted;
    },
    rawAmountPlotted: function () {
      return (
        parseInt(this.rawAmount) +
        parseInt(this.ephemeral_result.plotted_amount)
      );
    },
    discountedAmount: function () {
      return this.rawAmount - this.cartAmount;
    },
    rawAmount: function () {
      Cart.get().then((cart) => {
        this.productsInCart = (cart.items || []).length;
        this.totalCart = (cart.items || [])
          .map((i) => Number(i.full_price))
          .reduce((a, b) => a + b, 0);
      });
      return this.totalCart;
      //   return this.cartItems
      //     .map(
      //       i =>
      //         Number(i.quantity) *
      //         ((i.variation && i.variation.price) || i.product.price)
      //     )
      //     .reduce((a, b) => a + b, 0);
    },
    rawDiscount: function () {
      if (!this.enabled_purchase_discount) {
        return 0;
      }
      Cart.get().then((cart) => {
        this.productsInCart = (cart.items || []).length;
        this.totalCart = (cart.items || [])
          .map((i) => Number(i.full_price))
          .reduce((a, b) => a + b, 0);
      });
      if (this.totalCart < this.purchase_amount) {
        return 0;
      }
      return this.totalCart * this.discount_percentage;
    },
  },
  methods: {
    loadCart: function () {
      this.loadIndex++;
      return Cart.get()
        .then((cart) => {
          this.cartItems = cart.items || [];
          this.coupons = cart.coupons || [];
        })
        .finally(() => {
          this.loadIndex--;
        });
    },
    nextPhase: function () {
      if (this.phase === 1 && !this.shipping_method) {
        this.$swal({
          title: "Selecione uma forma de entrega para continuar!",
          text: "",
          icon: "info",
        });
        return;
      }
      window.scrollTo(0, 0);
      this.phase++;
      this.fixPhase();
    },
    previousPhase: function () {
      let phased = this.phase;
      this.phase--;
      this.fixPhase(true);
      let postPhased = this.phase;
      // We were already in the first possible phase when we pushed the button, so return
      if (postPhased === phased) {
        if (router.zhhasBefore) {
          router.go(-1);
        } else {
          router.push({ name: "cart" });
        }
      }
    },
    fixPhase: function (back = false) {
      if (this.phase < 0) {
        this.phase = 0;
        this.fixPhase(back);
        return;
      }
      switch (this.phase) {
        case 0:
          // Stop infinite recursion ffs
          if (back) {
            return;
          }
          // Skip shipping address selection if there is no need for shipping in the current order
          if (!this.hasShipping) {
            this.phase = this.phase + 1;
            this.fixPhase(back);
          }
          break;
        case 1:
          // Skip shipping method selection if there is no need for shipping in the current order, or if shipping is go_fetch
          if (!this.hasShipping) {
            this.phase = back ? this.phase - 1 : this.phase + 1;
            this.fixPhase(back);
          }
          break;
        case 2:
          // If order value is somehow 0 but there are items in the cart, proceed skipping payment data
          if (this.ephemeral_result && this.ephemeral_result.full_amount == 0) {
            this.phase = back ? this.phase - 1 : this.phase + 1;
            this.fixPhase(back);
          }
          break;
        // Phase does not exist, so do order finish here
        case 3:
          this.phase = this.phase - 1;
          this.generateOrder();
          break;
        default:
          return;
      }
    },
    buildImgUrl: function (item) {
      return buildImgUrl(item);
    },

    addCoupon: function () {
      let coupon_code = this.couponInput;
      Coupon.query(coupon_code, this.cartItems[0].product.supplier_id).then(
        (coupon) => {
          Coupon.apply(coupon.id).then(
            (appliedCoupon) => {
              this.coupons.push(appliedCoupon);
            },
            (error) => {
              this.$notify({
                duration: 24000,
                type: "error",
                title: "Erro ao Aplicar o Cupom!",
                text: util.stringifyAxiosError(error),
              });
            }
          );
        },
        (error) => {
          this.$notify({
            duration: 24000,
            type: "error",
            title: "Cupom não existe!",
            text: `O cupom '${coupon_code}' não existe! Confira o código do cupom!`,
          });
        }
      );
    },
    cepera: function (event = null, force = false) {
      if (!this.hasShipping) {
        return;
      }

      // Rebind manually because vue is being slow with the mask component
      if (event && this.destination_address) {
        this.destination_address.zipcode = event.target.value;
      }

      if (!this.zipcode || this.zipcode.length != 8) {
        // Invalidate state, input was changed
        this.isCepValid = false;
        this.lastCep = null;
        return;
      }

      // Ignore duplicate change
      if (this.lastCep == this.zipcode) {
        return;
      }

      this.loadIndex++;
      axios
        .get(
          "https://viacep.com.br/ws/" +
            String(this.zipcode).replace(/\D/g, "") +
            "/json"
        )
        .then((response) => {
          var dados = response.data;
          if (!("erro" in dados)) {
            // Atualiza os campos com os valores da consulta.
            this.destination_address.address = dados.logradouro;
            this.destination_address.neighborhood = dados.bairro;
            this.destination_address.city = dados.localidade;
            this.destination_address.state = dados.uf;
            // Register valid cep
            this.isCepValid = true;
            this.lastCep = this.zipcode;
            this.$forceUpdate();
            this.backdoor++;
            this.updateOrderShippingData();
          } else {
            this.isCepValid = false;
            this.$notify({
              duration: 24000,
              type: "error",
              title: "CEP Inválido!",
            });
          }
        })
        .finally(() => this.loadIndex--);
    },
    placeEphemeralOrder: function (override_shipping_method = null) {
      this.loadIndex++;
      const clonedAddress = JSON.parse(
        JSON.stringify(this.final_destination_address) || "{}"
      );
      if (clonedAddress && clonedAddress.zipcode) {
        clonedAddress.number = String(clonedAddress.number || 1);
      }


      

      Order.place({
        payment_method: this.payment_method,
        payment_data: this.payment_data,
        shipping_method: override_shipping_method || this.shipping_method,
        destination_address: clonedAddress,
        // We only want to simulate the order here to get potential shipping value
        // including rules in the same flow as the actual order
        ephemeral: true,
      })
        .then(
          (response) => {
            response.shipping_days =
              response.shipping_days ||
              Math.max(response.supplier_entries.map((s) => s.delivery_days));
            if (override_shipping_method) {
              this.ephemerals_by_shipping[override_shipping_method] =
                this.ephemerals_by_shipping[override_shipping_method] || {};
              this.ephemerals_by_shipping[override_shipping_method].result =
                response;
              if (override_shipping_method === "express_delivery") {
                this.raw_ephemeral_result = response;
              }
            } else {
              this.raw_ephemeral_result = response;
            }
            this.fixPhase();
            this.$forceUpdate();
            this.backdoor++;
          },
          (error) => {
            // eslint-disable-next-line
            console.error(error);
          }
        )
        .finally(() => {
          this.loadIndex--;
        });
    },
    updateOrderShippingData: function () {
      if (!this.hasShipping || !this.isCepValid) {
        return;
      }
      this.enabled_shipping_methods.forEach((enabled_shipping_method) => {
        this.placeEphemeralOrder(enabled_shipping_method);
        if (enabled_shipping_method === "go_fetch") {
          this.loadIndex++;
          Cart.shipping(this.zipcode, enabled_shipping_method)
            .then(
              (shipping_data) => {
                this.shipping_price = shipping_data.shipping_price;
                this.delivery_days = shipping_data.delivery_days;
              },
              (error) => {
                // eslint-disable-next-line
                console.error(error);
                this.shipping_price = null;
                this.delivery_days = null;
              }
            )
            .finally(() => {
              this.loadIndex--;
            });
        }
      });
    },
    generateOrder: debounce(

      

      function () {

        

        this.cartItems.forEach((element) => {
          var elementPrice = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "USD",
          }).format(element.unit_price / 100);
          var elementPrice = elementPrice.replace("R$", "");
          elementPrice = elementPrice.replace(" ", "");

          this.buildProductDataLayer = {
            item_id: element.id,
            item_name: element.product.name,
            price: elementPrice,
            item_category: !element.product.categories[0]
              ? "sem categoria"
              : element.product.categories[0].name,
            item_variant: !element.variation
              ? ""
              : element.product.variations[0].name,
            quantity: element.quantity,
            affiliation: process.env.VUE_APP_TENANT_ID,
            currency: "BRL",
          };

          this.dataLayerItems.push({
            item_id: element.id,
            item_name: element.product.name,
            price: elementPrice,
            item_category: !element.product.categories[0]
              ? "sem categoria"
              : element.product.categories[0].name,
            item_variant: !element.variation
              ? ""
              : element.product.variations[0].name,
            quantity: element.quantity,
            currency: "BRL",
          });
        });

        if (this.$gtm.enabled()) {
          window.dataLayer = window.dataLayer || [];

          var cartAmount = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(this.ephemeral_result.full_amount / 100);
          var finalCartAmount = cartAmount.replace("R$", "");
          finalCartAmount = finalCartAmount.replace(" ", "");

          var shippingPrice = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(this.ephemeral_result.shipping_amount / 100);
          var finalShippingPrice = shippingPrice.replace("R$", "");
          finalShippingPrice = finalShippingPrice.replace(" ", "");

          function makeid(length) {
            var result = "";
            var characters =
              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
              result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
              );
            }
            return result;
        } 
        }
        
        this.loadIndex++;
        if(this.final_destination_address) {
          this.final_destination_address.observation_message = this.observation_message_screen;
        }
        //this.final_destination_address.observation_message = this.observation_message_screen;
        Order.place({
          payment_method: this.payment_method,
          payment_data: this.payment_data,
          shipping_method: this.shipping_method,
          destination_address: this.final_destination_address,
          enabled_purchase_discount: this.enabled_purchase_discount,
          purchase_amount: this.purchase_amount,
          discount_percentage: this.discount_percentage,
          enabled_plotted: this.enabled_plotted,
        })
          .then(
            (response) => {
              router.push({
                name: "order-confirm",
                params: {
                  order_id: response.id,
                  order_object: response,
                },
              });
            },
            (error) => {
              this.$notify({
                duration: 24000,
                type: "error",
                title: "Erro ao processar o pedido!",
                text: util.stringifyAxiosError(error),
              });
            }
          )
          .finally(() => {
            this.loadIndex--;
          });
      },
      2000,
      true
    ),
  },
  watch: {
    zipcode: function () {
      this.cepera();
    },
    use_other_address: function (val) {
      if (val === "go_fetch") {
        this.shipping_method = "go_fetch";
      }
    },
  },
};
</script>